.auth-main {
  overflow: hidden;
  .auth-slider {
    height: calc(100vh - 100px);
    border-radius: 20px;

    .slider-pain {
      height: calc(100vh - 100px);
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 20px;
      background-position: center;
      padding: 32px;

      &.one {
        background-image: url("../assets/imgs/quicstock-splash-one.png");
      }

      &.two {
        background-image: url("../assets/imgs/quicstock-splash-two.png");
      }

      &.three {
        background-image: url("../assets/imgs/quicstock-splash-three.png");
      }
    }
  }
}

.auth-container {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  align-items: center;
  height: calc(100vh - 100px);
  justify-content: center;
}

.main-nav {
  height: 100vh;
}

.side-nav {
  width: 300px;

  .logo-container {
    .quickstock-logo-lg {
      display: block;
    }

    .quickstock-logo-sm {
      display: none;
    }
  }

  .quicstock-user-wrapper {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
}

.mobile-side-nav {
  width: 80px;

  .logo-container {
    display: flex;
    justify-content: center;

    .quickstock-logo-lg {
      display: none;
    }

    .quickstock-logo-sm {
      display: block;
    }
  }

  .mobile-quicstock-user-wrapper {
    padding-left: 0rem;
    padding-right: 0rem;
    .quicstock-user {
      display: none;
    }
  }
}

.accordion,
.mobile-accordion {
  width: 100%;
  border-radius: 0px;
  list-style: none;
  padding: 0;
  font-size: 16px;
}

.accordion .menu {
  position: relative;
  padding: 22px 14px;
  color: #898989;
  cursor: pointer;
  transition: all 0.4s ease;
  border-bottom: 0;
  font-size: 14px;

  .route-icon {
    height: 18px;
    svg {
      path {
        stroke: #898989;
      }
    }
  }

  &:hover {
    color: #ffffff !important;
    .route-icon {
      svg {
        path {
          stroke: #ffffff !important;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding-left: 30px;
    font-size: 14px;
  }
}

.mobile-accordion .menu {
  position: relative;
  padding: 22px 14px;
  padding-left: 0px;
  color: #898989;
  cursor: pointer;
  transition: all 0.4s ease;
  border-bottom: 0;
  font-size: 14px;

  .route-icon {
    height: 18px;
    svg {
      height: 20px;
      width: 20px;
      path {
        stroke: #898989;
      }
    }
  }

  .nav-label {
    display: none;
  }

  &:hover {
    color: #ffffff !important;
    .route-icon {
      svg {
        path {
          stroke: #ffffff !important;
        }
      }
    }
  }
}

.accordion li:last-child .menu,
.mobile-accordion li:last-child .menu {
  border-bottom: 0;
}

.accordion li.active i.fa-chevron-down,
.mobile-accordion li.active i.fa-chevron-down {
  transform: rotate(180deg);
}

.is_active {
  color: #ffffff !important;
  background: #ddedee;
  border-bottom: 0;
  border-radius: 8px;
  font-weight: 500;
}

.menu.is-active {
  color: #ffffff !important;
  font-weight: 400;
  .route-icon {
    height: 18px;
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }
}

.menu rect {
  fill: #e4e6eb !important;
}

.menu.is-active rect {
  fill: #ffffff !important;
}

.disable-div {
  pointer-events: none;
  cursor: not-allowed;
  color: #999999 !important;
}

.menu:hover,
.menu:active,
.menu:focus {
  outline: none !important;
  border: 0 !important;
  color: #ffffff !important;
}

.menu:hover .user-alt,
.menu:active .user-alt,
.menu:focus .user-alt {
  fill: #ffffff !important;
}

.clip-art {
  position: absolute;
}

.main-btn {
  color: #000000 !important;
  font-weight: 500 !important;
}

.main-content {
  overflow: auto;
  text-align: left;
  width: calc(100% - 300px);
  margin-left: auto;
  border-top-left-radius: 50px;
  transition: all 0.4s ease;

  .nav-toggle {
    width: 40.67px;
    height: 37.33px;
    top: 103px;
    left: 271px;
    padding: 8px 7px;
    border-radius: 10px;
    position: absolute;
    @apply bg-secondary-500;
    transition: all 0.4s ease;
    z-index: 100;

    &:hover {
      @apply bg-secondary-600;
    }
  }

  @media (max-width: 425px) {
    width: 0;
  }
}

.mobile-main-content {
  overflow: auto;
  text-align: left;
  width: calc(100% - 80px);
  margin-left: auto;
  border-top-left-radius: 50px;
  transition: all 0.4s ease;

  .nav-toggle {
    width: 40.67px;
    height: 37.33px;
    top: 103px;
    left: 51px;
    padding: 8px 7px;
    border-radius: 10px;
    position: absolute;
    @apply bg-secondary-500;
    transition: all 0.4s ease;
    z-index: 100;

    svg {
      transform: scale(-1, -1);
    }

    &:hover {
      @apply bg-secondary-600;
    }
  }
}

#mobile-nav {
  height: -webkit-fill-available;
}

.menu-items-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
