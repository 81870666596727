.otp-input input {
  width: 3em !important;
  text-align: center;
  outline-width: 0 !important;
  border: 1px solid #e4e6eb !important;
  border-radius: 4px;
  padding: 2px;
  height: 3em !important;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  color: #01061f;
}

.otp-container div:first-child {
  display: flex;
  position: relative;
  column-gap: 24px;
  align-items: center;
  justify-content: center;
  input {
    height: 50px !important;
    width: 60px !important;
    border-radius: 5px !important;
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border: 1px solid #efefef !important;
    background: #ffffff !important;

    &:focus {
      border: 1px solid #fc622f !important;
    }
  }
}

.opt-error {
  position: absolute;
  left: 0;
}

.gradient-border {
  position: relative;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  padding: 16px;
  cursor: pointer;

  &:hover::before {
    background: linear-gradient(
      90deg,
      #2763e9 0%,
      #ec8cda 50.93%,
      #fc622f 100%
    );
    transition: all 200ms ease-in-out;
  }
}

.gradient-border::before {
  content: "";
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(90deg, #d6d6d6 0%, #ffffff 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition: all 200ms ease-in-out;
}

.active-gradient-border {
  position: relative;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  padding: 16px;
  cursor: pointer;
}

.active-gradient-border::before {
  content: "";
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 10px;
  padding: 1.5px;
  background: linear-gradient(90deg, #2763e9 0%, #ec8cda 50.93%, #fc622f 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition: all 200ms ease-in-out;
}
